.messageBar {
	display:block;
	min-height:50px;
	background:#000;
	color:#fff;
	padding:15px 15px;
	text-align:center;
	animation: bgChange 3s infinite;
	overflow:hidden;
}

@keyframes bgChange {
	0%   {background-color: #000;}
  50%  {background-color: #BE0A00;}
  100% {background-color: #000;}
}

.messageBar:hover {
  color:#fff ;
}

.loading-box{
	text-align: center;
	padding: 40px;
}
body,
h1, h2, h3, h4, h5, h6,
header .group-content-cars ul .makeName,
.jcarousel-control-prev,.jcarousel-control-next{
	font-family: Calibri;
}